var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"bg"},[_c('Navbar',{attrs:{"display":false}}),_c('v-main',[_c('v-container',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'px-16' : 'px-4',attrs:{"fluid":""}},[_c('header',{staticClass:"my-8"},[_c('h1',{staticClass:"text--h1"},[_vm._v("Project List and Create")]),_c('p',{staticClass:"text--subtitle-1 grey--text text--darken-1"},[_vm._v(" You can create and access projects here ")])]),_c('v-toolbar',{staticClass:"bg",attrs:{"flat":""}},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" My Projects ")]),(_vm.invites.length > 0)?_c('v-tab',[_c('v-badge',{attrs:{"color":"primary","content":_vm.invites.length}},[_vm._v(" Invites ")])],1):_c('v-tab',[_vm._v(" Invites ")])],1),_c('v-spacer'),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{staticClass:"text-capitalize",attrs:{"large":"","rounded":"","color":"primary","to":"/project/create"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Create Project ")],1):_vm._e()],1),_c('v-tabs-items',{staticClass:"bg mt-8",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"transition":"fade-transition","reverse-transition":"fade-transition"}},[_c('v-row',[_vm._l((_vm.projects),function(project){return _c('v-col',{key:project.id,class:_vm.$vuetify.breakpoint.mdAndUp
									? 'pl-0 pr-4'
									: 'px-0',attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 4 : 12}},[_c('v-card',{staticClass:"mb-0",attrs:{"width":"100%"}},[_c('div',{staticClass:"mx-0 border pb-5 pa-4"},[_c('h2',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(project.name)+" ")]),(project.domain)?_c('a',{staticClass:"website-domain",attrs:{"href":`http://${project.domain}`,"target":"_blank"}},[_vm._v(" "+_vm._s(project.domain)+" ")]):_c('p',[_vm._v("No domain assigned, yet.")])]),_c('v-row',{staticClass:"mx-0 pa-4 d-flex"},[_c('v-col',{staticClass:"pa-0 d-flex justify-space-between",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","large":"","outlined":"","color":"primary","to":{
												name: 'dashboard',
												params: {
													projectId: project.id,
												},
											}}},[_vm._v(" Explore ")])],1)],1)],1)],1)}),_c('v-col',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.mdAndUp
									? 'pl-0 pr-4'
									: 'px-0',attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 4 : 12}},[_c('v-card',{staticClass:"create pb-5 mb-0 pa-4"},[_c('div',{staticClass:"d-flex flex-column justify-space-around"},[_c('p',{staticClass:"text-center mt-6 text-h5"},[_vm._v(" Planning for your next project? ")]),_c('v-btn',{staticClass:"align-self-center text-capitalize mt-6 mb-4",attrs:{"large":"","rounded":"","color":"primary","to":"/project/create"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Create Project ")],1)],1)])],1)],2)],1),_c('v-tab-item',{attrs:{"transition":"fade-transition","reverse-transition":"fade-transition"}},[(_vm.invites.length > 0)?_c('v-row',{staticClass:"mx-0 d-flex"},_vm._l((_vm.invites),function(invite){return _c('v-col',{key:invite.id,staticClass:"d-flex",class:_vm.$vuetify.breakpoint.mdAndUp
									? 'pl-0 pr-4'
									: 'px-0',attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 4 : 12}},[_c('v-card',{staticClass:"mb-0",attrs:{"width":"100%"}},[_c('div',{staticClass:"border px-4 py-2"},[_c('h2',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(invite.project.name)+" ")]),_c('div',{staticClass:"mb-2"},[_c('strong',{staticClass:"pr-2"},[_vm._v(" Invited By: ")]),_c('span',[_vm._v(" "+_vm._s(invite.invitedBy.firstName)+" "+_vm._s(invite.invitedBy.lastName)+" ")])]),_c('div',{staticClass:"mb-2"},[_c('strong',{staticClass:"pr-2"},[_vm._v(" Roles: ")]),_vm._l((invite.roles),function(role,index){return _c('span',{key:role.index},[(
													index !=
													invite.roles.length - 1
												)?_c('span',{staticClass:"text-lowercase"},[_vm._v(" "+_vm._s(role.name + ' ,')+" ")]):_c('span',{staticClass:"text-lowercase"},[_vm._v(" "+_vm._s(role.name)+" ")])])})],2)]),_c('v-row',{staticClass:"mx-0 pa-4 d-flex"},[_c('v-col',{staticClass:"pa-0 d-flex justify-space-between",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"outlined":"","color":"var(--blue)"},on:{"click":function($event){return _vm.rejectProjectInvite(
													invite.id
												)}}},[_vm._v(" Reject ")]),_c('v-btn',{staticClass:"users",attrs:{"color":"success"},on:{"click":function($event){return _vm.acceptProjectInvite(
													invite.id
												)}}},[_vm._v(" Accept ")])],1)],1)],1)],1)}),1):_c('v-row',[_c('NoData',{attrs:{"title":"No invitations, yet.","subtitle":"No any project invitations, yet. You will get one when someone wants to collaborate."}})],1)],1)],1)],1)],1),_c('Snackbar')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }