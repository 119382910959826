<template>
	<v-app class="bg">
		<!-- Navbar -->
		<Navbar :display="false"></Navbar>

		<v-main>
			<v-container
				fluid
				:class="$vuetify.breakpoint.mdAndUp ? 'px-16' : 'px-4'"
			>
				<!-- Header Content -->
				<header class="my-8">
					<h1 class="text--h1">Project List and Create</h1>

					<p class="text--subtitle-1 grey--text text--darken-1">
						You can create and access projects here
					</p>
				</header>

				<!-- Tab Menu -->
				<v-toolbar flat class="bg">
					<!-- Tabs on Left -->
					<v-tabs v-model="tab">
						<!-- Tab 1 -->
						<v-tab> My Projects </v-tab>

						<!-- Tab 2 -->
						<v-tab v-if="invites.length > 0">
							<v-badge color="primary" :content="invites.length">
								Invites
							</v-badge>
						</v-tab>
						<v-tab v-else> Invites </v-tab>
					</v-tabs>

					<v-spacer></v-spacer>

					<!-- Create Project Button on Right -->
					<v-btn
						v-if="$vuetify.breakpoint.mdAndUp"
						large
						rounded
						color="primary"
						to="/project/create"
						class="text-capitalize"
					>
						<v-icon left>mdi-plus</v-icon>
						Create Project
					</v-btn>
				</v-toolbar>

				<!-- Tab Screens -->
				<v-tabs-items v-model="tab" class="bg mt-8">
					<!-- My Projects Screen -->
					<v-tab-item
						transition="fade-transition"
						reverse-transition="fade-transition"
					>
						<v-row>
							<v-col
								v-for="project in projects"
								:key="project.id"
								:cols="$vuetify.breakpoint.mdAndUp ? 4 : 12"
								:class="
									$vuetify.breakpoint.mdAndUp
										? 'pl-0 pr-4'
										: 'px-0'
								"
							>
								<v-card class="mb-0" width="100%">
									<div class="mx-0 border pb-5 pa-4">
										<h2 class="mb-2">
											{{ project.name }}
										</h2>

										<a
											v-if="project.domain"
											:href="`http://${project.domain}`"
											target="_blank"
											class="website-domain"
										>
											{{ project.domain }}
										</a>
										<p v-else>No domain assigned, yet.</p>
									</div>

									<v-row class="mx-0 pa-4 d-flex">
										<v-col
											cols="12"
											class="
												pa-0
												d-flex
												justify-space-between
											"
										>
											<v-btn
												block
												large
												outlined
												color="primary"
												:to="{
													name: 'dashboard',
													params: {
														projectId: project.id,
													},
												}"
											>
												Explore
											</v-btn>
										</v-col>
									</v-row>
								</v-card>
							</v-col>

							<v-col
								:cols="$vuetify.breakpoint.mdAndUp ? 4 : 12"
								:class="
									$vuetify.breakpoint.mdAndUp
										? 'pl-0 pr-4'
										: 'px-0'
								"
								class="d-flex"
							>
								<v-card class="create pb-5 mb-0 pa-4">
									<div
										class="
											d-flex
											flex-column
											justify-space-around
										"
									>
										<p class="text-center mt-6 text-h5">
											Planning for your next project?
										</p>
										<v-btn
											large
											rounded
											color="primary"
											class="
												align-self-center
												text-capitalize
												mt-6
												mb-4
											"
											to="/project/create"
										>
											<v-icon left>mdi-plus</v-icon>
											Create Project
										</v-btn>
									</div>
								</v-card>
							</v-col>
						</v-row>
					</v-tab-item>

					<!-- Project Invites Screen -->
					<v-tab-item
						transition="fade-transition"
						reverse-transition="fade-transition"
					>
						<v-row v-if="invites.length > 0" class="mx-0 d-flex">
							<v-col
								v-for="invite in invites"
								:key="invite.id"
								:cols="$vuetify.breakpoint.mdAndUp ? 4 : 12"
								:class="
									$vuetify.breakpoint.mdAndUp
										? 'pl-0 pr-4'
										: 'px-0'
								"
								class="d-flex"
							>
								<v-card class="mb-0" width="100%">
									<div class="border px-4 py-2">
										<h2 class="mb-2">
											{{ invite.project.name }}
										</h2>

										<div class="mb-2">
											<strong class="pr-2">
												Invited By:
											</strong>
											<span>
												{{ invite.invitedBy.firstName }}
												{{ invite.invitedBy.lastName }}
											</span>
										</div>

										<div class="mb-2">
											<strong class="pr-2">
												Roles:
											</strong>
											<span
												v-for="(
													role, index
												) in invite.roles"
												:key="role.index"
											>
												<span
													v-if="
														index !=
														invite.roles.length - 1
													"
													class="text-lowercase"
												>
													{{ role.name + ' ,' }}
												</span>
												<span
													v-else
													class="text-lowercase"
												>
													{{ role.name }}
												</span>
											</span>
										</div>
									</div>

									<v-row class="mx-0 pa-4 d-flex">
										<v-col
											cols="12"
											class="
												pa-0
												d-flex
												justify-space-between
											"
										>
											<v-btn
												outlined
												@click="
													rejectProjectInvite(
														invite.id
													)
												"
												color="var(--blue)"
											>
												Reject
											</v-btn>

											<v-btn
												class="users"
												color="success"
												@click="
													acceptProjectInvite(
														invite.id
													)
												"
											>
												Accept
											</v-btn>
										</v-col>
									</v-row>
								</v-card>
							</v-col>
						</v-row>

						<v-row v-else>
							<NoData
								title="No invitations, yet."
								subtitle="No any project invitations, yet. You will get one when someone wants to collaborate."
							></NoData>
						</v-row>
					</v-tab-item>
				</v-tabs-items>
			</v-container>
		</v-main>
		<!-- Snackbar Notification -->
		<Snackbar />
	</v-app>
</template>

<script>
import Navbar from '@/components/Navbar'
import NoData from '../../components/NoData'
import Snackbar from '@/components/Snackbar'

export default {
	components: {
		Navbar,
		NoData,
		Snackbar,
	},
	data() {
		return {
			projectId: null,
			tab: 0,
		}
	},
	computed: {
		projects: {
			get() {
				return this.$store.state.project.projects
			},
		},
		invites: {
			get() {
				return this.$store.state.project.invites
			},
		},
	},
	created() {
		this.$store.dispatch('project/getProjectListingData')
		this.$store.dispatch('project/getProjectInvitesData')
	},
	destroyed() {
		this.$store.commit('project/clearState')
	},
	methods: {
		acceptProjectInvite(id) {
			this.$store.dispatch('project/acceptProjectInvite', id)
		},
		rejectProjectInvite(id) {
			this.$store.dispatch('project/rejectProjectInvite', id)
		},
	},
}
</script>

<style scoped>
.bg {
	background-color: #f7fafc !important;
}
.border {
	border-bottom: 1px solid #eee;
}
.v-card {
	width: 100% !important;
}
.website-domain {
	text-decoration: none;
	font-size: 18px;
}
</style>
